.arrow {
  font-size: var(--title-1);
  height: 0.75em;
  line-height: 0.875;
  display: inline-block;
  overflow: hidden;

  &:not(.passive) {
    & > span {
      animation: arrow-in 1.25s var(--primary-curve);
    }
  }

  & > span {
    display: inline-block;
    transform: translate3d(0, -0.13em, 0);
  }
}

.passive {
  opacity: 0;
}

@keyframes arrow-in {
  from {
    transform: translate3d(0, -1em, 0);
  }
}
