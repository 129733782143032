@import "../../../styles/abstracts";

.underlinedLink {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  font-size: var(--title-4);
  line-height: 1.2;
  clip-path: inset(-2px);

  @include before {
    height: 3px;
    width: 30px;
    background: var(--light-grey);
    position: absolute;
    left: -30px;
    bottom: -1px;
    z-index: 10;
    transform: var(--base-translate3d);
    transition: left 0.85s var(--primary-curve);
    pointer-events: none;
  }

  @include after {
    margin-top: 22px;
    display: inline-block;
    width: 100%;
    height: 1px;
    background: var(--black);
  }

  &:hover {
    &:before {
      left: calc(100% + 35px);
    }
  }
}
