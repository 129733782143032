@import "../../../styles/abstracts";

.homeAboutContainer {
  position: relative;
}

.homeAbout {
  @include appGrid;
  padding: var(--section-vertical-padding) 0;
}

.title {
  grid-column-start: 3;
  grid-column-end: 7;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 11;
    grid-column-end: 13;
  }
}

.hobbies {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 2;
  margin-top: 65px;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 3;
    grid-column-end: 10;
    grid-row-start: 1;
    margin-top: 0;
  }

  h2 {
    margin-bottom: 0.3em;
  }
}

.coverImageContainer {
  margin: 12px 0 20px;
  width: 100%;
  height: 290px;

  @media screen and (min-width: #{$screen-large}) {
    margin: 40px 0 35px;
    width: calc(100px + ((100% - 150px) / 7) * 5);
    height: calc(((100vw - var(--full-screen-gutters)) / 2.4) * 0.65);
  }
}

.coverImage {
  width: calc(100vw - (var(--layout-horizontal-padding) * 2));
  height: 356px;
  object-position: left;

  @media screen and (min-width: #{$screen-large}) {
    width: 100%;
    height: calc(((100vw - var(--full-screen-gutters)) / 2.4) * 0.8);
    object-position: initial;
  }
}

.description {
  margin-top: 38px;
  grid-column-start: 1;
  grid-column-end: 7;
  padding-right: calc(((100% - 50px) / 6));

  @media screen and (min-width: #{$screen-large}) {
    margin-top: 72px;
    grid-column-start: 3;
    padding-right: calc(((100% - 75px) / 6));
  }
}

.aboutLink {
  grid-column-start: 1;
  grid-column-end: 7;
  margin-top: 45px;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 7;
    grid-column-end: 10;
    margin-top: 0;
    align-self: end;
  }
}
