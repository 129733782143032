@import "../../../styles/abstracts";

.homeProfileContainer {
  position: relative;
}

.homeProfile {
  @include appGrid;
  padding: var(--section-vertical-padding) 0;
}

.bio {
  grid-column-start: 1;
  grid-column-end: 7;
  margin: 25px 0 45px;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 4;
    grid-column-end: 13;
    margin: 0 0 135px;
    padding-right: calc((100% - 200px) / 9);
  }
}

.personalInterests {
  margin-top: 1.2em;
}

.advantageTitle {
  grid-column-start: 1;
  grid-column-end: 7;
  max-width: 230px;
  margin-bottom: 35px;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 4;
    grid-column-end: 7;
    max-width: 280px;
    margin-bottom: 0;
    padding-right: calc(((100% - 50px) / 6));
  }

  h4 {
    line-height: 1.32;
  }
}

.advantage {
  grid-column-start: 1;
  grid-column-end: 7;
  margin-bottom: 45px;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 7;
    grid-column-end: 11;
    margin-bottom: 65px;
  }

  p {
    line-height: 1.32;
  }
}

.aboutLink {
  grid-column-start: 1;
  grid-column-end: 7;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 7;
    grid-column-end: 11;
  }
}
