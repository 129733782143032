@import "../../../styles/abstracts";

.homeProjectsContainer {
  position: relative;
}

.homeProjects {
  @include appGrid;
  padding: var(--section-vertical-padding) 0;
}

.titleTwo {
  grid-column-start: 3;
  grid-column-end: 7;
  max-width: 140px;
  margin-bottom: 70px;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 4;
    max-width: 190px;
    margin-bottom: 120px;
  }
}

.titleThree {
  display: none;

  @media screen and (min-width: #{$screen-large}) {
    display: initial;
    grid-column-start: 9;
    grid-column-end: 12;
    max-width: 190px;
  }
}
