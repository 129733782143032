@import "../../../styles/abstracts";

.cover {
  position: relative;
  @include fullVertical;
  clip-path: inset(0px calc(10px + (100% - 50px) / 6 * 1) 0px 0px);
  pointer-events: none;

  @media screen and (min-width: #{$screen-large}) {
    margin: -22px 0;
    clip-path: inset(22px calc(175px + (100% - 275px) / 12 * 7) 22px 0px);
  }

  &:not(.passive) {
    animation: mask-in 1.25s var(--primary-curve);

    @media screen and (min-width: #{$screen-large}) {
      animation: lg-mask-in 1.25s var(--primary-curve);
    }

    .coverImage {
      animation: image-in 1.25s var(--primary-curve);
    }
  }

  @media screen and (min-width: #{$screen-large}) {
    pointer-events: all;
  }
}

.passive {
  opacity: 0;
  pointer-events: none;
}

.coverImageContainer {
  transform: translate3d(0%, 0px, 0px);
}

.coverImage {
  width: 100%;
  height: 290px;
  object-fit: cover;
  object-position: 8% 0;
  transform: var(--base-scale3d);
  transform-origin: 25% 50%;
  transition: transform 1s var(--primary-curve);

  @media screen and (min-width: #{$screen-large}) {
    height: 100%;
  }

  @media screen and (min-width: #{$screen-desktop}) {
    object-position: 0 35%;
    min-height: 270px;
    max-height: calc(100vh - 710px);
  }
}

.hotspot {
  position: absolute;
  top: 0;
  right: calc(175px + (100% - 275px) / 12 * 7);
  bottom: 0;
  left: 0;
}

@keyframes mask-in {
  from {
    clip-path: inset(100% calc(10px + (100% - 50px) / 6 * 1) 0 0px);
  }
}

@keyframes lg-mask-in {
  from {
    clip-path: inset(
      calc(100% - 22px) calc(175px + (100% - 275px) / 12 * 7) 22px 0px
    );
    pointer-events: none;
  }

  to {
    pointer-events: none;
  }
}

@keyframes image-in {
  from {
    transform-origin: 25% 0;
    transform: scale3d(1.3, 1.3, 1.3);
  }

  to {
    transform-origin: 25% 0;
  }
}
