@import "../../../styles/abstracts";

.homeHeroContainer {
  position: relative;
}

.homeHero {
  @include appGrid;
  grid-row-gap: 20px;
  align-content: start;
  margin-top: 100px;
  min-height: calc(100vh - (var(--header-height) + var(--layout-top-padding)));
  padding-bottom: var(--section-vertical-padding);

  @media screen and (min-width: #{$screen-large}) {
    grid-row-gap: 35px;
    margin-top: 0;
  }
}

.title {
  @include fullVertical;
}

.arrow {
  grid-column-start: 6;
  grid-column-end: 7;
  align-self: end;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.description {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  width: 255px;
  margin-bottom: 10px;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 3;
    align-self: end;
    width: 280px;
    margin-bottom: 0;
  }
}

.name {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 4;

  @media screen and (min-width: #{$screen-large}) {
    grid-column-start: 6;
    grid-column-end: 13;
    grid-row-start: 3;
    text-align: right;
  }
}
